/* Warning: please do not statically import any dependencies here except for static assets like icons */
// import icon from './file-code-solid.svg'
// import { ReactComponent as icon } from './file-code-solid.svg'
import { EyeIcon } from "@panwds/icons";

import Registry from "@ui-platform/extensibility";
import { ControlService } from "./services/control.service";
import { ExtensionsConfig, Extensions } from "@sparky/framework";
import { SPARKY_NAV3_TSG_STATE_LOADERS_EXTPOINT, SPARKY_NAV3_LEFT_NAV_EXTPOINT, SPARKY_NAV3_ROUTES_EXTPOINT } from "@sparky/framework/extpoints";

/**
 *  V2 Nav
 * */

export default function init({ sparkyFramework, React }) {
  const isIoTAllowed = () => {
    const zingbox = ControlService.getInstance().getZingboxInstance();
    if (zingbox) {
      return ControlService.getInstance().hasIoTAccess();
    }
    return false;
  };

  const isCustomDashboardAllowed = () => {
    // return false;
    return isIoTAllowed();
  };

  Registry.contributeExtension("vnr.ia.dashboards", {
    id: "iot_dashboard_oneapp",
    config: {
      title: "IoT Security",
      key: "iot_dashboard_oneapp",
      path: "/iot", // Final path for the page will be /dashboards/iot
      group: "activity", // "health" | "activity" | "posture"
      name: {
        defaultMessage: "IoT Security",
      },
      component: () => import("./containers/app/App"),
      isAllowed: () => isIoTAllowed(),
      position: ["end"],
    },
  });

  Registry.contributeExtension("external_widgets", {
    id: "iot.OverallComponent",
    config: {
      id: "iot.OverallComponent",
      title: "Overall Summary",
      description: "Overall Summary Widget",
      icon: "chart-line",
      category: "IoT Security",
      widgetSettings: [],
      layoutConfig: {
        h: 10,
        w: 12,
        x: 0,
      },
      component: React.lazy(() => import("./containers/main/CustomDashboardWidgetWrapper")),
      enabled: () => isCustomDashboardAllowed(),
    },
  });

  Registry.contributeExtension("external_widgets", {
    id: "iot.DevicesComponent",
    config: {
      id: "iot.DevicesComponent",
      title: "Devices Distribution",
      description: "Devices Distribution Widget",
      icon: "chart-line",
      category: "IoT Security",
      widgetSettings: [],
      layoutConfig: {
        h: 20,
        w: 12,
        x: 0,
      },
      component: React.lazy(() => import("./containers/main/CustomDashboardWidgetWrapper")),
      enabled: () => isCustomDashboardAllowed(),
    },
  });

  Registry.contributeExtension("external_widgets", {
    id: "iot.TopOSComponent",
    config: {
      id: "iot.TopOSComponent",
      title: "Top Operating System",
      description: "Top OS Component Widget",
      icon: "chart-line",
      category: "IoT Security",
      widgetSettings: [],
      layoutConfig: {
        h: 20,
        w: 4,
        x: 0,
      },
      component: React.lazy(() => import("./containers/main/CustomDashboardWidgetWrapper")),
      enabled: () => isCustomDashboardAllowed(),
    },
  });

  Registry.contributeExtension("external_widgets", {
    id: "iot.SubnetComponent",
    config: {
      id: "iot.SubnetComponent",
      title: "Network Distribution",
      description: "Network Distribution Widget",
      icon: "chart-line",
      category: "IoT Security",
      widgetSettings: [],
      layoutConfig: {
        h: 20,
        w: 8,
        x: 0,
      },
      component: React.lazy(() => import("./containers/main/CustomDashboardWidgetWrapper")),
      enabled: () => isCustomDashboardAllowed(),
    },
  });

  return function tsgLoad() {
    return {
      navigation: [
        {
          key: "iot",
          path: "/iot",
          noRBACheck: true,
          exact: true,
          hidden: true,
          component: () => import("./containers/app/App"),
        },
        {
          key: "insights",
          title: "Insights",
          expandable: true,
          path: "/insights",
          noRBACheck: true,
          exact: false,
          icon: EyeIcon, // icon
          mergeNav: true, // required if the first level is shared
          children: [
            {
              key: "assets",
              title: "IoT Security",
              path: "/insights/iot-security",
              noRBACheck: true,
              avail: () => isIoTAllowed(),
              contentClassName: "panwds-tw3", // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
              component: () => import("./containers/app/App"),
            },
          ],
        },
      ],
    };
  };
}

/**
 *  V3 Nav
 *  Lazy removed due to lazy autodetect & noRBACheck not needed
 * */

const nav3TsgStateLoadersExt = {
  point: SPARKY_NAV3_TSG_STATE_LOADERS_EXTPOINT,
  configs: [{
    id: 'sparky.nav.state.loaders.main',
    config: {
      name: 'main',
      requireFetch: true,
      loader: () => {
        const isIoTAllowed = () => {
          const zingbox = ControlService.getInstance().getZingboxInstance();
          if (zingbox) {
            return ControlService.getInstance().hasIoTAccess();
          }
          return false;
        };
      
        const isCustomDashboardAllowed = () => {
          // return false;
          return isIoTAllowed();
        };
      
        Registry.contributeExtension("vnr.ia.dashboards", {
          id: "iot_dashboard_oneapp",
          config: {
            title: "IoT Security",
            key: "iot_dashboard_oneapp",
            path: "/iot", // Final path for the page will be /dashboards/iot
            group: "activity", // "health" | "activity" | "posture"
            name: {
              defaultMessage: "IoT Security",
            },
            component: () => import("./containers/app/App"),
            isAllowed: () => isIoTAllowed(),
            position: ["end"],
          },
        });
      
        Registry.contributeExtension("external_widgets", {
          id: "iot.OverallComponent",
          config: {
            id: "iot.OverallComponent",
            title: "Overall Summary",
            description: "Overall Summary Widget",
            icon: "chart-line",
            category: "IoT Security",
            widgetSettings: [],
            layoutConfig: {
              h: 10,
              w: 12,
              x: 0,
            },
            component: () => import("./containers/main/CustomDashboardWidgetWrapper"),
            enabled: () => isCustomDashboardAllowed(),
          },
        });
      
        Registry.contributeExtension("external_widgets", {
          id: "iot.DevicesComponent",
          config: {
            id: "iot.DevicesComponent",
            title: "Devices Distribution",
            description: "Devices Distribution Widget",
            icon: "chart-line",
            category: "IoT Security",
            widgetSettings: [],
            layoutConfig: {
              h: 20,
              w: 12,
              x: 0,
            },
            component: () => import("./containers/main/CustomDashboardWidgetWrapper"),
            enabled: () => isCustomDashboardAllowed(),
          },
        });
      
        Registry.contributeExtension("external_widgets", {
          id: "iot.TopOSComponent",
          config: {
            id: "iot.TopOSComponent",
            title: "Top Operating System",
            description: "Top OS Component Widget",
            icon: "chart-line",
            category: "IoT Security",
            widgetSettings: [],
            layoutConfig: {
              h: 20,
              w: 4,
              x: 0,
            },
            component: () => import("./containers/main/CustomDashboardWidgetWrapper"),
            enabled: () => isCustomDashboardAllowed(),
          },
        });
      
        Registry.contributeExtension("external_widgets", {
          id: "iot.SubnetComponent",
          config: {
            id: "iot.SubnetComponent",
            title: "Network Distribution",
            description: "Network Distribution Widget",
            icon: "chart-line",
            category: "IoT Security",
            widgetSettings: [],
            layoutConfig: {
              h: 20,
              w: 8,
              x: 0,
            },
            component: () => import("./containers/main/CustomDashboardWidgetWrapper"),
            enabled: () => isCustomDashboardAllowed(),
          },
        });
      }
    }
  }]
} as ExtensionsConfig

const nav3LeftNavExt = {
  point: SPARKY_NAV3_LEFT_NAV_EXTPOINT,
  configs: [{
    id: 'sparky.nav.left.iot',
    config: {
      stateLoaders: ['auth', 'main'],
      navigation: () => {
        const isIoTAllowed = () => {
          const zingbox = ControlService.getInstance().getZingboxInstance();
          if (zingbox) {
            return ControlService.getInstance().hasIoTAccess();
          }
          return false;
        };

        return [
          {
            path: "/iot",
            component: () => import("./containers/app/App"),
          },
          {
            title: "Insights",
            expandable: true,
            path: "/insights",
            noRBACheck: true,
            icon: EyeIcon,
            children: [
              {
                key: "assets",
                title: "IoT Security",
                path: "/insights/iot-security",
                noRBACheck: true,
                avail: () => isIoTAllowed(),
                contentClassName: "panwds-tw3", // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
                component: () => import("./containers/app/App"),
              },
            ],
          },
        ];
      }
    }
  }]
} as ExtensionsConfig

const nav3RouteExt = {
  point: SPARKY_NAV3_ROUTES_EXTPOINT,
  configs: [
    {
      id: 'sparky.nav.routes.iot',
      config: {
        path: '/iot',
        exact: true,
        stateLoaders: ['auth', 'main']
      }
    }
  ],
} as ExtensionsConfig

export const extensions: Extensions = [
  nav3TsgStateLoadersExt,
  nav3LeftNavExt,
  nav3RouteExt
];
