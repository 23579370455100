
import { getFrameworkVars, getAuthState } from "@sparky/framework";
import { hasRBACAccess } from "../utils/rbac";

export function isAvailable() {
    // display AI Runtime for AIOPS premium tenants with CDL license
    // TODO revisit condition along with SCM pricing and packaging
    const isPremium = !getFrameworkVars().aiops_free;
    const hasCDL = getAuthState()?.instances?.isRunningStatus("logging_service");
    const enforceRbacV2 = false; //getFeatureFlag?.("vnr.rbac_v2") ?? false;
    return enforceRbacV2 ? isPremium && hasCDL && hasRBACAccess("ai_security", getAuthState()) : isPremium && hasCDL;
}